<template>
  <div v-if="false"></div>
</template>

<script>
export default {
  mounted() {
    this.$emit("created");
  },
};
</script>
